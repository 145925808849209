@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $color-black;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	text-align: center;
	text-transform: uppercase;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
	overflow-x: hidden;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

.container {
	max-width: 1000px;
}

header {
	background: url('../img/bg/bg.png') no-repeat center center;
	background-size: cover;
	padding: rem(45) 0 0 0;

	h1 {
		font-family: 'Oswald', sans-serif;
		font-size: rem(40);
		font-weight: 700;
		letter-spacing: letter-spacing(200);
		line-height: line-height(48, 40);
		margin: 0;
		position: relative;
		text-indent: text-indent(200);

		z-index: 2;

		@include tablet-down {
			font-size: rem(30);
		}
	}

	.logo {
		left: 50%;
		margin: rem(-100) auto 0 auto;
		max-width: 100vw;
		position: relative;
		transform: translateX(-50%);
		z-index: 1;

		@include tablet-down {
			margin-top: rem(-50);
		}
	}
}

main {
	.container {
		max-width: 700px;
	}

	.prize {

		margin-top: rem(-40);
		position: relative;
		z-index: 3;

		> img {
			border: 2px solid $color-white;
			margin: 0 auto;
			width: rem(400);
		}

		> span {
			display: block;
			font-size: rem(14);
			font-style: italic;
			margin-top: rem(20);
		}
	}

	.terms {
		font-size: rem(16);
		line-height: line-height(30, 16);
		padding-top: rem(40);
		text-transform: none;

		p {
			margin: 0;

			+ p {
				margin-top: rem(20);
			}
		}
	}

	.methods {
		> div {
			margin-top: rem(130);

			h2 {
				font-size: rem(20);
				font-weight: 700;
				letter-spacing: letter-spacing(800);
				margin: 0 0 rem(40) 0;
				text-indent: text-indent(800);
			}

			p {
				font-size: rem(16);
				letter-spacing: letter-spacing(200);
				line-height: line-height(30, 16);
				margin-bottom: rem(40);
				text-indent: text-indent(200);

				a {
					color: $color-purple;
					text-decoration: underline;

					@include hover-focus {
						color: $color-white;
						text-decoration: none;
					}
				}
			}

			ul {
				list-style: none;
				margin: 0 0 rem(75) 0;
				padding: 0;

				> li {
					display: inline-block;
					font-size: rem(40);
					vertical-align: middle;

					+ li {
						margin-left: rem(25);
					}

					> a {
						color: $color-purple;

						@include hover-focus {
							color: $color-white;
						}
					}
				}
			}

			.btn {
				background: $color-purple;
				color: $color-white;
				display: inline-block;
				font-size: rem(14);
				font-weight: 700;
				letter-spacing: letter-spacing(400);
				line-height: 1;
				padding: rem(20) rem(30);
				text-align: center;
				text-indent: text-indent(400);
				text-transform: uppercase;
				vertical-align: middle;

				@include hover-focus {
					background: $color-white;
					color: $color-purple;
				}
			}

			.step-3 {
				margin-top: rem(130);

				&.locked {
					.klaviyo-form {
						position: relative;

						&:after {
							background: rgba(0, 0, 0, 0.5);
							bottom: 0;
							content: '';
							left: 0;
							position: absolute;
							right: 0;
							top: 0;
							z-index: 10;
						}
					}
				}

				/*form {
					> div {
						margin-bottom: rem(30);

						> input {
							background: $color-black;
							border: 0;
							border-bottom: 2px solid $color-white;
							color: $color-white;
							display: block;
							font-size: rem(16);
							letter-spacing: letter-spacing(200);
							line-height: 1;
							padding: 0 0 rem(10) 0;
							text-align: center;
							text-indent: text-indent(200);
							width: 100%;

							@include placeholder {
								color: $color-white;
								text-transform: uppercase;
							}
						}
					}

					.btn {
						margin-top: rem(20);
					}
				}*/
			}
		}
	}
}

footer {
	font-size: rem(12);
	letter-spacing: letter-spacing(800);
	line-height: line-height(16, 12);
	padding: rem(80) 0 rem(50) 0;
	text-indent: text-indent(800);

	p {
		margin: 0;

		+ p {
			margin-top: rem(10);
		}

		a {
			@include hover-focus {
				color: $color-purple;
			}
		}
	}

	.container {
		max-width: 700px;
	}
}